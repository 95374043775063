import React, { useState, useEffect, useRef } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import parse from 'html-react-parser'
import Wrapper from '../../components/wrapper'
import { permissionsHandler } from '../../functions'
import {
  BellIcon,
  FaceBookIcon,
  LinkedInIcon,
  ShareIcon,
  DownloadIcon,
  TwitterXIcon,
} from '../../components/icons'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { message } from 'antd'
import { strapiURL } from '../../config'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share'
import html2canvas from 'html2canvas'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import cookies from 'react-cookies'
import moment from 'moment'
import BlogCard from '../../components/pageComponents/common/blogCard'
import GatedContent from '../../components/gatedContent'
import BlogFilter from '../../components/pageComponents/blogFilter'
import FeaturedJob from '../../components/pageComponents/featuredJob'
import BlogADBlock from '../../components/pageComponents/blogADBlock'
import BlogLatest from '../../components/pageComponents/blogLatest'
import BlogPopular from '../../components/pageComponents/blogPopular'

const HIDE_PERCENT = 0.3

const BlogPage = ({ data, location }) => {
  let article = data.strapiArticle && data.strapiArticle
  let user = cookies.load('user')
  let currentUrl = typeof window !== 'undefined' && window?.location?.href

  const [blogDetails, setBlogDetails] = useState({})
  const [contentHeight, setContentHeight] = useState('unset')
  const contentRef = useRef()
  const storeProducts = useSelector(state => state.user).products
  let products = permissionsHandler(storeProducts, user?.role?.id)
  const downloadDivRef = useRef(null)
  let jsPDF
  if (typeof window !== 'undefined') {
    jsPDF = require('jspdf').jsPDF
  }

  useEffect(() => {
    if (user) {
      if (contentRef.current) {
        setContentHeight(contentRef.current?.clientHeight)
      }
    } else {
      if (contentRef.current) {
        setContentHeight(contentRef.current?.clientHeight * HIDE_PERCENT)
      }
    }
  }, [article])

  useEffect(() => {
    axios.get(strapiURL + `/articles/count/${article.slug}`).then(res => {
      setBlogDetails(res.data)
    })
  }, [])

  const updateBlogHandler = key => {
    let _copy = { ...blogDetails }
    _copy[key] = _copy[key] ? parseInt(_copy[key]) + 1 : 1
    axios
      .put(strapiURL + `/articles/updateCount/${article.slug}`, {
        value: _copy,
      })
      .then(res => {
        setBlogDetails(_copy)
      })
      .catch(err => {
        console.log('check err::::')
      })
  }

  const downloadBlog = async () => {
    if (typeof window !== 'undefined') {
      const padHorizontal = 24 // 0.25 Inch
      const padVertical = 24 // 0.25 Inch
      const inputDiv = document.getElementById('downloadDiv')
      const pdf = new jsPDF('p', 'mm', 'a4', true)
      const pageWidth = pdf.internal.pageSize.width - padHorizontal * 2
      const pageHeight = pdf.internal.pageSize.height - padVertical * 2
      const divWidth = inputDiv.clientWidth
      const divHeight = inputDiv.clientHeight
      let offsetHeight = 0
      const imageHeight = (pageHeight * divWidth) / pageWidth
      while (offsetHeight < divHeight) {
        const actualHeight =
          offsetHeight + imageHeight < divHeight
            ? imageHeight
            : divHeight - offsetHeight

        const canvas = await html2canvas(inputDiv, {
          x: 0,
          y: offsetHeight,
          width: divWidth,
          height: actualHeight,
        })

        const contentDataURL = canvas.toDataURL('image/png')
        const actualPageHeight = (actualHeight * pageHeight) / imageHeight

        pdf.addImage(
          contentDataURL,
          'PNG',
          padHorizontal,
          padVertical,
          pageWidth,
          actualPageHeight,
          '',
          'FAST'
        )

        if (offsetHeight + imageHeight < divHeight) {
          pdf.addPage()
        }

        offsetHeight = offsetHeight + imageHeight
      }

      pdf.save(`${article?.title?.split(' ').join('_')}.pdf`)
      updateBlogHandler('download_count')
    }
  }

  const handleCategoryChange = val => {
    const params = new URLSearchParams({
      category: val,
    })
    navigate(`/medical-sales-careers/?${params}`)
  }

  const handleKeywordChange = val => {
    const params = new URLSearchParams({
      category: article.category,
      keyword: val,
    })
    navigate(`/medical-sales-careers/?${params}`)
  }

  const Children = props => {
    return (
      <>
        <div className="py-0 lg:py-12 container mx-auto">
          <div className="block lg:hidden">
            <BlogFilter
              activeCategory={article.articleCategory?.name}
              keyword=""
              onChangeCategory={handleCategoryChange}
              onChangeKeyword={handleKeywordChange}
            />
          </div>

          <div className="block lg:flex">
            <div className="pr-0 mr-0 border-0 lg:pr-6 lg:mr-6 lg:border-r border-black flex-[3]">
              <div
                className="w-full px-[30px] lg:px-0"
                ref={downloadDivRef}
                id="downloadDiv"
              >
                <h1
                  className={`text-black text-[28px] lg:text-[34pt] font-medium font-playfair leading-tight mb-6 ${
                    article?.is_gated ? 'gated-article' : ''
                  }`}
                >
                  {article.title}
                </h1>

                <div className="flex justify-between lg:items-center">
                  <p className="font-sans mb-0 text-[14px] lg:text-[18px]">
                    {moment(article?.publishAt || article?.published_at).format(
                      'll'
                    )}
                  </p>

                  <div className="flex items-start">
                    <div>
                      <div className="flex items-center">
                        <div className="mr-4 flex items-center">
                          <FacebookShareButton
                            beforeOnClick={() => {
                              updateBlogHandler('share_count')
                            }}
                            url={currentUrl}
                          >
                            <FaceBookIcon />
                          </FacebookShareButton>
                        </div>
                        <div className="mr-4 flex items-center">
                          <LinkedinShareButton
                            beforeOnClick={() => {
                              updateBlogHandler('share_count')
                            }}
                            url={currentUrl}
                          >
                            <LinkedInIcon />
                          </LinkedinShareButton>
                        </div>
                        <div className="mr-4 flex items-center">
                          <TwitterShareButton
                            beforeOnClick={() => {
                              updateBlogHandler('share_count')
                            }}
                            url={currentUrl}
                          >
                            <TwitterXIcon />
                          </TwitterShareButton>
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <div
                        className="flex flex-col items-center cursor-pointer"
                        onClick={() => downloadBlog()}
                      >
                        <DownloadIcon />
                        <p className="font-sans mb-0 text-sm mt-[8px]">
                          {blogDetails.download_count || 0}
                        </p>
                      </div>
                      <div className="flex flex-col items-center cursor-pointer ml-6">
                        <CopyToClipboard
                          onCopy={() => {
                            message.info('Link copied to clipboard.')
                            updateBlogHandler('share_count')
                          }}
                          text={
                            typeof window !== 'undefined' &&
                            window.location.href
                          }
                        >
                          <button>
                            <ShareIcon />
                            <p className="font-sans mb-0 text-sm mt-[10px]">
                              {blogDetails.share_count || 0}
                            </p>
                          </button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </div>
                {article?.featured_image?.url ? (
                  <div
                    className="w-full px-[30px] lg:px-0 mx-auto min-h-[188px] lg:min-h-[616px] h-full my-12 bg-cover bg-center bg-no-repeat relative bg-[#edecec]"
                    style={{
                      backgroundImage: `url(${article?.featured_image?.url})`,
                    }}
                  />
                ) : (
                  <div
                    className="w-full lg:w-10/12 px-[30px] lg:px-0 mx-auto min-h-[400px] lg:min-h-[500px] h-full mt-12 bg-contain bg-center bg-no-repeat relative bg-[#edecec]"
                    style={{
                      backgroundImage: `url(https://remotemore.com/images/macbook-right.png)`,
                    }}
                  />
                )}

                <div className="relative">
                  <div
                    ref={contentRef}
                    style={
                      article?.is_gated &&
                      (!user ||
                        !products?.subscriptionPurchased ||
                        products?.currentSubscription?.pending)
                        ? { height: `${contentHeight}px`, maxHeight: '400px' }
                        : { height: `${contentHeight}px` }
                    }
                    className="article-content font-sans overflow-hidden"
                  >
                    {parse(article.content, {
                      replace: domNode => {
                        if (domNode.name === 'a') {
                          domNode.attribs.target = '_blank'
                          domNode.attribs.rel = 'noopener'
                          return domNode
                        }
                      },
                    })}
                  </div>
                  {article?.is_gated &&
                  (!user ||
                    !products?.subscriptionPurchased ||
                    products?.currentSubscription?.pending) ? (
                    <div className="absolute left-0 w-full h-full bottom-0 bg-gradient-to-b from-[#ffffff00] to-[#ffffffff]"></div>
                  ) : (
                    <div />
                  )}
                </div>

                <div className="pt-12">
                  <GatedContent
                    openLoginModal={props.openLoginModal}
                    openSignupModal={props.openSignupModal}
                    viewMethod="vertical"
                    classes={'bg-[#FBEFF3]'}
                  ></GatedContent>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="hidden lg:block">
                <BlogFilter
                  activeCategory={article.articleCategory?.name}
                  keyword=""
                  onChangeCategory={handleCategoryChange}
                  onChangeKeyword={handleKeywordChange}
                />
              </div>
              <FeaturedJob />
              <BlogADBlock id={'article_detail'} />
              <BlogLatest />
            </div>
          </div>
          <BlogPopular />
        </div>

        <div className="py-12">
          <div className="w-full lg:w-10/12 px-[30px] lg:px-0 mx-auto">
            <div className="w-full" ref={downloadDivRef} id="downloadDiv"></div>
            {article?.related?.length ? (
              <div className="mt-20">
                <div className="flex items-center">
                  <BellIcon />
                  <p className="ml-3 text-lg font-sans font-semibold w-full mb-0">
                    You may also like from Featured
                  </p>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 mt-4 lg:mt-12">
                  {article?.related?.map((article, articleIndex) => {
                    return (
                      <Link
                        to={`/medical-sales-careers/${article.slug}`}
                        key={articleIndex}
                      >
                        <BlogCard article={article} />
                      </Link>
                    )
                  })}
                  <div className="w-full"></div>
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <Wrapper
      location={location}
      title={`${article.title} | MedReps.com`}
      data={data}
      sourceKey="strapiArticle"
      metaKeywords={article?.keywords}
    >
      <Children />
    </Wrapper>
  )
}
export default BlogPage
export const pageQuery = graphql`
  query ($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      id
      title
      slug
      articleCategory {
        name
      }
      content
      featured_image {
        url
      }
      related {
        featured_image {
          url
        }
        id
        title
        slug
        articleCategory {
          name
        }
        content
        description
      }
      is_gated
      published_at
      publishAt
      keywords
      seo {
        sharedImage {
          url
        }
      }
    }
  }
`
